import { get, put, getTotal } from "./api";
import { createRange } from "utils/SearchUtil";

export async function getCompanies() {
  const res = await get("company?sort=+name");
  const { data } = await get("company?sort=+name", [], createRange(0, getTotal(res)));

  return data;
}

export async function getRegistrationCompany() {
  const { data } = await get("application/community/registrationCompany");

  return data;
}

export async function getAnnouncements(companyId) {
  const { data } = await get(`ams/announcements/${companyId}`);
  return data;
}

export async function editInventory(payload) {
  await put("ams/inventory", payload);
}

export async function getTimeZoneCodes() {
  const { data } = await get("timeZones");
  return data;
}

export async function getCompanyConfigurations() {
  const { data = [] } = await get("application/community/company/configs");
  return data;
}
export async function getCompanyLocations() {
  const res = await get("location");
  const { data } = await get("location", [], createRange(0, getTotal(res)));
  return data;
}
