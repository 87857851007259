import {
  VEHICLES_SET_PER_PAGE,
  VEHICLES_SET_PAGE,
  INVENTORY_SEARCH_OPTIONS_RESET_STATE,
  INVENTORY_SET_QUERY_STRING,
  INVENTORY_SET_SELECTED_SEARCH_OPTIONS,
  INVENTORY_DETAILS_GET_LOT_ID,
  INVENTORY_RESET_SELECTED_SEARCH_OPTION,
  INVENTORY_TOGGLE_SEARCH_OPTION,
  INVENTORY_ADD_SEARCH_OPTION_FROM_PARAMS,
  INVENTORY_UPSERT,
  INVENTORY_SET_SORT_BY,
  INVENTORY_SET_SORT_ORDER,
  INVENTORY_TOGGLE_SORT_BY,
  ASSET_LINKS_FETCH,
  ASSET_LINKS_FETCH_COMPLETE,
  SET_PARENT_PAGE_CATEGORY,
  CALENDAR_FETCH,
  CALENDAR_FETCH_COMPLETE
} from "./actionTypes";
import { getCalendarEntitiesForInventory as getCalendarEntitiesForInventoryApi } from "api/calendarRequests";
import { fastlaneGetLotId } from "api/scriptRequests";
import {
  createWatch,
  removeWatch,
  createNote as createNoteAPI,
  updateNote as updateNoteAPI,
  deleteNote as deleteNoteAPI,
  getCondition,
  search,
  getAssets as getAssetsAPI,
  getDisclosures as getDisclosuresAPI,
  getLot,
  getLinks
} from "api/inventoryRequests";
import { showNotification, dismiss, showError } from "./notificationActions";
import * as SearchUtil from "utils/SearchUtil";
import { getRange } from "utils/Pagination";
import isEmpty from "lodash/isEmpty";
import { createTermFilter, createTermsFilter, createRangeFilter } from "utils/SearchUtil";
import moment from "moment";
import { trackSearch } from "hooks/useTracking";
import { setTotal, remove } from "reducers/lots";
import condition from "reducers/conditions";
import { fetchAssets, fetchAssetsComplete } from "reducers/assets";
import { fetch as fetchDisclosures, upsert as upsertDisclosures } from "reducers/disclosures";
import notesSlice from "reducers/notes";
import { calculateAgeSelectedSearchOptions } from "reducers/inventorySearchOptions";
import { getLanguageMap } from "reducers/language";
import { toLowerCase } from "utils/StringUtil";
import { getAssetSearchFilters } from "utils/SearchOptionsUtil";
import { getSimilarItems as getSimilarItemsAPI } from "api/recommenderRequests";
import { toMap } from "utils/CollectionUtil";
import { setSimilarItems } from "reducers/similarItems";
import { Severity } from "types/alert";
import {
  inventoryFetch,
  inventoryFetchComplete,
  recommendationFetchComplete
} from "./sharedActions";
import { formatDate } from "utils/DateUtil";

export function upsertInventory(inventory) {
  return {
    type: INVENTORY_UPSERT,
    payload: inventory
  };
}

export function searchInventory(newPage) {
  return async (dispatch, getState) => {
    const {
      vehicles: { page, perPage, sortBy },
      inventorySearchOptions: {
        ageDateUnit,
        selectedSearchOptions: uiSchemaSelectedSearchOptions,
        defaultSelectedSearchOptions
      }
    } = getState();

    dispatch(inventoryFetch());

    try {
      const filters = buildSearchFilters({
        ...defaultSelectedSearchOptions,
        ...calculateAgeSelectedSearchOptions(ageDateUnit, defaultSelectedSearchOptions)
      });

      filters.push(
        ...getAssetSearchFilters({
          ...uiSchemaSelectedSearchOptions,
          ...calculateAgeSelectedSearchOptions(ageDateUnit, uiSchemaSelectedSearchOptions)
        })
      );

      const { data: inventory } = await search(
        filters,
        getRange(newPage ?? page, perPage),
        sortBy ? sortBy.split(",") : undefined
      );
      trackSearch(filters, "/inventory");

      dispatch(inventoryFetchComplete(inventory));
      dispatch(setTotal(inventory.total));
      if (typeof newPage === "number") {
        dispatch(setVehiclesPage(newPage));
      }

      return inventory;
    } catch (error) {
      dispatch(dismiss());
      // dispatch(fetchInventoryComplete(null, error));
      return [];
    }
  };
}

export function getCalendarEntitiesForInventory() {
  return async (dispatch, getState) => {
    try {
      const {
        calendar: { saleType, selectedAssetSchemasIds }
      } = getState();

      dispatch({ type: CALENDAR_FETCH });

      const startDate = moment()
        .startOf("day")
        .valueOf();

      const filters = [createRangeFilter("endTime", startDate, "GTE")];

      if (saleType) {
        let saleTypes = [saleType.toLowerCase(), saleType.toLowerCase() + "_legacy"];
        filters.push(createTermsFilter("saleTypes", saleTypes));
      }
      if (selectedAssetSchemasIds?.length > 0) {
        filters.push(createTermsFilter("assetSchemaIds", selectedAssetSchemasIds));
      }

      const { total } = await getCalendarEntitiesForInventoryApi(filters, "items=0-0");

      const { data: payload } = await getCalendarEntitiesForInventoryApi(
        filters,
        getRange(0, total),
        "+startTime"
      );

      const results = { ...payload };
      results.shouldMerge = false;
      results.lastPageType = "inventory";

      dispatch({ type: CALENDAR_FETCH_COMPLETE, payload: results });

      return results;
    } catch (error) {
      return {};
    }
  };
}

function defaultLabelsGetter({ lot, asset, company }, locale) {
  const labels = [];

  labels.push({ label: "odometer", value: asset?.uifields?.field2.value });

  const showLocation =
    lot.filterable?.saleType && !lot.filterable.saleType.toLowerCase().includes("timed");

  labels.push({
    label: "auction house",
    value: company?.name,
    value2: showLocation ? lot.filterable?.locationName : ""
  });

  if (lot.startTime) {
    labels.push({
      label: "event date",
      value: formatDate(lot.startTime, locale)
    });
  }

  return labels;
}

export function getSimilarItems(lotId, getLabels = defaultLabelsGetter) {
  return async (dispatch, getState) => {
    const {
      companies: { companies },
      user: { user }
    } = getState();

    const filters = [getSaleEndTimeFilter()];

    const results = await getSimilarItemsAPI(lotId, filters);

    const { lots = [], assets = [], medias = [] } = results;

    const assetMap = assets.reduce(toMap("id"), {});
    const mediaMap = medias.reduce(toMap("entityId"), {});
    const companyMap = companies.reduce(toMap("id"), {});

    const recommendedItems = lots.reduce((acc, lot) => {
      const item = {};

      item.lotId = lot.id;
      item.assetId = lot.assetId;
      item.media = mediaMap[lot.assetId];
      item.companyName = companyMap[lot.companyId]?.name;
      item.title = assetMap[lot.assetId]?.uifields?.header?.value;
      item.labels = getLabels(
        {
          lot,
          asset: assetMap[lot.assetId],
          media: mediaMap[lot.assetId],
          company: companyMap[lot.companyId]
        },
        user?.locale
      );

      acc.push(item);
      return acc;
    }, []);

    dispatch(setSimilarItems(recommendedItems));

    const { disclosures, inspections, memberships, watches } = results;

    dispatch(recommendationFetchComplete({ disclosures, inspections, memberships, watches }));
  };
}

export function getFastlaneItemLotId(assetNumber, eventNumber, companyCode) {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: INVENTORY_DETAILS_GET_LOT_ID,
        payload: { assetNumber, eventNumber, companyCode }
      });
      return fastlaneGetLotId(assetNumber, eventNumber, companyCode);
    } catch (error) {
      const inventoryText = getLanguageMap(getState())?.["inventory"] || "inventory";
      dispatch(showError(`Unable to find ${toLowerCase(inventoryText)} item`));
    }
  };
}

export function getInventoryItem(lotId) {
  return async dispatch => {
    dispatch(inventoryFetch());
    const filters = [createTermFilter("lot.id", lotId)];
    const lot = await getLot(lotId);
    if (lot) {
      filters.push(createTermFilter("sale.id", lot.saleId));
    }
    let { data: inventory } = await search(filters, "items=0-0");
    dispatch(inventoryFetchComplete(inventory));
    return inventory;
  };
}

export function fetchConditions(inspectionId) {
  return async dispatch => {
    try {
      dispatch(condition.actions.fetch());

      const filters = [createTermFilter("inspectionId", inspectionId)];

      const { total } = await getCondition(filters, "items=0-0");
      const { data: conditions } = await getCondition(filters, `items=0-${total}`);

      dispatch(condition.actions.fetchComplete(conditions));
    } catch (error) {
      dispatch(condition.actions.fetchComplete([]));
    }
  };
}

export function resetConditions() {
  return async dispatch => {
    dispatch(condition.actions.fetchComplete([]));
  };
}

export function setVehiclesPerPage(perPage) {
  return {
    type: VEHICLES_SET_PER_PAGE,
    payload: {
      perPage
    }
  };
}

export function setVehiclesPage(page) {
  return {
    type: VEHICLES_SET_PAGE,
    payload: {
      page
    }
  };
}

export function resetInventorySearchOptionsState(marketSegment) {
  return {
    type: INVENTORY_SEARCH_OPTIONS_RESET_STATE,
    payload: {
      marketSegment
    }
  };
}

export function selectSearchOptionFromParams(searchOption, value, page = 0) {
  return {
    type: INVENTORY_ADD_SEARCH_OPTION_FROM_PARAMS,
    payload: {
      searchOption,
      value,
      page
    }
  };
}

export function resetSelectedSearchOption(searchOption) {
  return {
    type: INVENTORY_RESET_SELECTED_SEARCH_OPTION,
    payload: {
      searchOption
    }
  };
}

export function toggleSearchOption(searchOption, value) {
  return {
    type: INVENTORY_TOGGLE_SEARCH_OPTION,
    payload: {
      searchOption,
      value: value ?? null
    }
  };
}

export function vehiclesInventorySetSortBy(sortBy) {
  return {
    type: INVENTORY_SET_SORT_BY,
    payload: {
      sortBy
    }
  };
}

export function vehiclesInventorySetSortOrder(sortOrder) {
  return {
    type: INVENTORY_SET_SORT_ORDER,
    payload: {
      sortOrder
    }
  };
}

export function inventoryToggleSortBy(sortBy) {
  return {
    type: INVENTORY_TOGGLE_SORT_BY,
    payload: {
      sortBy
    }
  };
}

export function inventorySetSelectedSearchOptions(selectedSearchOptions) {
  return {
    type: INVENTORY_SET_SELECTED_SEARCH_OPTIONS,
    payload: {
      selectedSearchOptions
    }
  };
}

export function addItemToWatchList(lotId) {
  return async dispatch => {
    try {
      await createWatch(lotId);
      dispatch(showNotification("Added to watchlist", Severity.success));
    } catch (error) {}
  };
}

export function createNote(note) {
  return async dispatch => {
    try {
      let noteId = await createNoteAPI(note);
      note.id = noteId;
      note.createdOn = Date.now();
      dispatch(notesSlice.actions.addInventoryNote(note));
      dispatch(showNotification("Note created", Severity.success));
    } catch (error) {}
  };
}

export function updateNote(note) {
  return async dispatch => {
    try {
      await updateNoteAPI(note);
      dispatch(notesSlice.actions.updateInventoryNote({ id: note.entityId, changes: note }));
      dispatch(showNotification("Note updated", Severity.info));
    } catch (error) {}
  };
}

export function deleteNote(note) {
  return async dispatch => {
    await deleteNoteAPI(note);
    dispatch(notesSlice.actions.deleteInventoryNote(note.entityId));
    dispatch(showNotification("Note cleared", Severity.info));
  };
}

export function fetchAssetDisclosures(assetId) {
  return async dispatch => {
    dispatch(fetchDisclosures());
    const filters = [createTermFilter("assetId", assetId)];
    let { total } = await getDisclosuresAPI(filters);
    let { data: disclosures } = await getDisclosuresAPI(filters, `items=0-${total}`);

    dispatch(upsertDisclosures(disclosures));
  };
}

export function removeVehicleFromWatchList(watchId, lotId, shouldRemoveLot = false) {
  return async dispatch => {
    await removeWatch(watchId);

    if (shouldRemoveLot) {
      dispatch(remove(lotId));
    }

    dispatch(showNotification("Removed from watchlist", Severity.info));
  };
}

export function setQueryString(queryString) {
  return {
    type: INVENTORY_SET_QUERY_STRING,
    payload: {
      queryString
    }
  };
}

function reduceFilters(accumulator, filter) {
  return accumulator.concat(filter);
}

//TODO: this should be a selector?
export function buildSearchFilters(selectedSearchOptions = {}) {
  const filters = Object.keys(selectedSearchOptions)
    .filter(key => {
      const value = selectedSearchOptions[key];
      const type = typeof value;
      return value === true || !isEmpty(value) || type === "number";
    })
    .map(key => {
      let value = selectedSearchOptions[key];
      if (Array.isArray(value)) {
        switch (key) {
          case "companies":
            return SearchUtil.createTermsFilter("companyId", value);
          case "saleType":
            return SearchUtil.createTermsFilter("sale.saleType", value);
          case "events":
            return SearchUtil.createTermsFilter("sale.eventId", value);
          case "sales":
            return SearchUtil.createTermsFilter("sale.id", value);
          case "rangeNumbers":
            return SearchUtil.createTermsFilter("lot.rangeNumber", value);
          case "consignor":
            return SearchUtil.createTermsFilter("lot.consignorName", value);
          case "consignorType":
            return SearchUtil.createTermsFilter("lot.consignorType", value);
          case "consignorIds":
            return SearchUtil.createTermsFilter("lot.consignorId", value);
          case "lotStatus":
            return SearchUtil.createTermsFilter("lot.status", value);
          default:
            return SearchUtil.createTermsFilter(`asset.fields.${key}`, value);
        }
      } else {
        switch (key) {
          case "fromYear":
            return SearchUtil.createRangeFilter("asset.fields.year", value, "GTE");
          case "toYear":
            return SearchUtil.createRangeFilter("asset.fields.year", value, "LTE");
          case "fromMileage":
            return SearchUtil.createRangeFilter("asset.fields.mileage", value, "GTE");
          case "toMileage":
            return SearchUtil.createRangeFilter("asset.fields.mileage", value, "LTE");
          case "fromGrade":
            return SearchUtil.createRangeFilter(
              "asset.fields.overallConditionGradeNumber",
              value,
              "GTE"
            );
          case "toGrade":
            return SearchUtil.createRangeFilter(
              "asset.fields.overallConditionGradeNumber",
              value,
              "LTE"
            );
          case "hasCR":
            return SearchUtil.createTermFilter(
              "asset.fields.hasConditionReport",
              value === "hasCR"
            );
          case "fromAge":
            return SearchUtil.createRangeFilter("asset.fields.age", value, "GTE");
          case "toAge":
            return SearchUtil.createRangeFilter("asset.fields.age", value, "LTE");
          case "fromAverageWeight":
            return SearchUtil.createRangeFilter("asset.fields.averageWeight", value, "GTE");
          case "toAverageWeight":
            return SearchUtil.createRangeFilter("asset.fields.averageWeight", value, "LTE");
          case "fromBaseWeight":
            return SearchUtil.createRangeFilter("asset.fields.baseWeight", value, "GTE");
          case "toBaseWeight":
            return SearchUtil.createRangeFilter("asset.fields.baseWeight", value, "LTE");
          case "fromQuantity":
            return SearchUtil.createRangeFilter("asset.fields.quantity", value, "GTE");
          case "toQuantity":
            return SearchUtil.createRangeFilter("asset.fields.quantity", value, "LTE");
          case "fromTotalWeight":
            return SearchUtil.createRangeFilter("asset.fields.totalWeight", value, "GTE");
          case "toTotalWeight":
            return SearchUtil.createRangeFilter("asset.fields.totalWeight", value, "LTE");
          case "fromHeadCount":
            return SearchUtil.createRangeFilter("asset.fields.headCount", value, "GTE");
          case "toHeadCount":
            return SearchUtil.createRangeFilter("asset.fields.headCount", value, "LTE");
          case "fromWeaningWeight":
            return SearchUtil.createRangeFilter("asset.fields.weaningWeight", value, "GTE");
          case "toWeaningWeight":
            return SearchUtil.createRangeFilter("asset.fields.weaningWeight", value, "LTE");
          case "fromYearlingWeight":
            return SearchUtil.createRangeFilter("asset.fields.yearlingWeight", value, "GTE");
          case "toYearlingWeight":
            return SearchUtil.createRangeFilter("asset.fields.yearlingWeight", value, "LTE");
          case "odometerUnit":
            return SearchUtil.createTermFilter("asset.fields.odometerUnit", value);
          case "saleId":
            return SearchUtil.createTermFilter("sale.id", value);
          case "watchlist":
            return SearchUtil.createTermFilter("watchlist", value);
          case "note":
            return SearchUtil.createTermFilter("note", value);
          case "queryString":
            return SearchUtil.createQueryFilter(value);
          case "consignor":
            return SearchUtil.createTermFilter("lot.consignorName", value);
          case "MMR":
            return [
              SearchUtil.createRangeFilter("asset.fields.estimates.average", 0, "LTE", "MUST_NOT"),
              SearchUtil.createTermFilter("asset.fields.estimates.source", "Mmr")
            ];
          case "keys":
            return SearchUtil.createRangeFilter("asset.fields.keys", 1, "GTE", "MUST");
          case "upcomingInventory":
            return SearchUtil.createTermFilter(
              "sale.saleType",
              "staged",
              isTypeSelected("unlisted", value) ? "MUST" : "MUST_NOT"
            );
          case "titleStatus":
            return SearchUtil.createTermFilter("asset.fields.titleStatus", value);
          case "saleType":
            return SearchUtil.createTermFilter(
              "sale.saleType",
              "TIMED",
              isTypeSelected("timed", value) ? "MUST" : "MUST_NOT"
            );
          case "buyItNow":
            return getBuyItNowFilters();
          case "groupedItems":
            return SearchUtil.createExistsFilter("lot.groupNumber", "MUST");
          case "pregnant":
            return SearchUtil.createTermFilter("asset.fields.pregnantBool", true, "MUST");
          case "owner":
            return SearchUtil.createTermFilter("asset.fields.owner", value);
          case "feed":
            return SearchUtil.createTermFilter("asset.fields.feed", value);
          case "health":
            return SearchUtil.createTermFilter("asset.fields.health", value);
          case "hauled":
            return SearchUtil.createTermFilter("asset.fields.hauled", value);
          case "weaned":
            return SearchUtil.createTermFilter("asset.fields.weaned", value === "weaned");
          case "sex":
            return SearchUtil.createTermFilter("asset.fields.sex", value);
          case "color":
            return SearchUtil.createTermFilter("asset.fields.color", value);
          case "frame":
            return SearchUtil.createTermFilter("asset.fields.frame", value);
          case "breed":
            return SearchUtil.createTermFilter("asset.fields.breed", value);
          case "flesh":
            return SearchUtil.createTermFilter("asset.fields.flesh", value);
          case "comments":
            return SearchUtil.createTermFilter("asset.fields.comments", value);
          case "location":
            return SearchUtil.createTermFilter("asset.fields.location", value);
          case "assetSchemaId":
            return SearchUtil.createTermFilter("asset.fields.schemaId", value);
          case "asIs": {
            switch (value) {
              case "As-is":
                return SearchUtil.createTermFilter("asset.fields.redLight", true, "MUST");
              case "Not As-is":
                return SearchUtil.createTermFilter("asset.fields.redLight", false, "SHOULD");
              case "Caution":
                return SearchUtil.createTermFilter("asset.fields.yellowLight", true, "MUST");
              default:
            }
            throw new Error("Invalid asIs filter");
          }
          default:
            throw new Error("Invalid filter");
        }
      }
    })
    .reduce(reduceFilters, []);

  if (shouldAddSaleEndTimeFilter(selectedSearchOptions)) {
    filters.push(getSaleEndTimeFilter());
  }

  return filters;
}

function shouldAddSaleEndTimeFilter(selectedSearchOptions) {
  return !isTypeSelected("unlisted", selectedSearchOptions.upcomingInventory);
}

function isTypeSelected(type, value) {
  return type?.toLowerCase?.() === value?.toLowerCase?.();
}

function getBuyItNowFilters() {
  return [
    SearchUtil.createRangeFilter("lot.buyNowAmount", 1, "GTE", "MUST"),
    SearchUtil.createTermFilter("sale.allowBuyItNow", true),
    SearchUtil.createTermsFilter("sale.status", ["CLOSED", "COMPLETED"], "MUST_NOT"),
    SearchUtil.createTermsFilter("lot.status", ["PREBID", "UNSOLD"])
  ];
}

function getSaleEndTimeFilter() {
  return SearchUtil.createRangeFilter(
    "sale.endTime",
    moment()
      .startOf("day")
      .valueOf(),
    "GTE"
  );
}

export function getAssets(category, filters, page = 0, sort) {
  return async dispatch => {
    dispatch(fetchAssets());

    filters.push(createTermFilter("status", "REMOVED", "MUST_NOT"));

    const { data: assets, total } = await getAssetsAPI(category, filters, getRange(page, 50), sort);

    dispatch(fetchAssetsComplete({ assets, total }));

    return assets;
  };
}

export function fetchAssetLinks(assetId, companyId) {
  return async dispatch => {
    dispatch({ type: ASSET_LINKS_FETCH });

    let links;
    try {
      const filters = [
        createTermFilter("entityId", assetId),
        createTermFilter("companyId", companyId),
        createTermFilter("entityType", "ASSET")
      ];

      const { data, total } = await getLinks(filters);
      links = data;

      if (total > data.length) {
        const { data } = await getLinks(filters, `items=0-${total}`);
        links = data;
      }
    } finally {
      dispatch({ type: ASSET_LINKS_FETCH_COMPLETE, payload: links });
    }

    return links;
  };
}

export function setParentPageCategory(parentPageCategory) {
  return {
    type: SET_PARENT_PAGE_CATEGORY,
    payload: { parentPageCategory }
  };
}
