import {
  ATTENDANCE_GET_AGREEMENTS,
  ATTENDANCE_GET_DEFAULT_AGREEMENT,
  ATTENDANCE_SET_AGREEMENTS,
  ATTENDANCE_SET_AGREES,
  ATTENDANCE_AGREE,
  ATTENDANCE_GET_AGREES,
  ATTENDANCE_SET_DEALERSHIPS,
  ATTENDANCE_SET_DEALERSHIP_ID,
  MEMBERSHIP_CREATE,
  MEMBERSHIP_UPDATE,
  MEMBERSHIPS_GET,
  MEMBERSHIPS_SET,
  MEMBERSHIPS_SET_TOTAL,
  MEMBERSHIPS_SET_PAGE,
  MEMBERSHIP_TOGGLE_FILTER,
  MEMBERSHIP_SET_SORT_BY,
  MEMBERSHIP_SAVE,
  MEMBERSHIP_SAVE_COMPLETE,
  MEMBERSHIP_TOGGLE_HIDDEN_COLUMN
} from "./actionTypes";
import {
  getAgreements as getAgreementsFromApi,
  getAgrees as getAgreesFromApi,
  agree as createAgree,
  getAgreement,
  createMembership as createMembershipAPI,
  updateMembership as updateMembershipAPI,
  adminUpdateMembership as adminUpdateMembershipAPI,
  getMemberships as getMembershipsFromApi,
  fetchAuthorizedSaleUsers,
  getMembership,
  enableSaleUsers as enableSaleUsersApi,
  enablePrioritySaleUsers
} from "api/attendanceRequests";
import { updateInteractionField } from "api/interactionRequests";
import { createTermFilter, createTermsFilter, createQueryFilter } from "utils/SearchUtil";
import { getRange } from "utils/Pagination";
import { getCredits } from "actions/creditActions";
import { showNotification, dismiss, showError } from "actions/notificationActions";
import { saleUsersFetchComplete, saleUsersFetch, saleUsersFetchError } from "reducers/saleUsers";
import { openDialogWithProps } from "actions/dialogActions";
import { getEvent } from "api/calendarRequests";
import { getUserDealerships } from "api/attendanceRequests";
import { eventsSelector } from "reducers/events";
import { batch } from "utils/StoreUtil";
import { getCompanyUser as getCompanyUserAPI } from "api/authRequests";
import { setUserLastDealershipSyncMessage } from "./userActions";
import { DIALOG_CONTENT_TYPE } from "utils/constants";
import { getCompanyUsers, getMembershipSchema, getMembershipSchemaFields } from "api/adminRequests";
import { getMembershipForUser } from "reducers/memberships";
import { Severity } from "types/alert";
import GoNative from "services/gonative";
import { trackEvent } from "hooks/useTracking";
import { canAttend } from "reducers/attendance";
import { getAttendUrl, getGuestAttendUrl } from "api/saleRequests";
import StorageUtil from "utils/StorageUtil";
import { isStringNumeric } from "utils/NumberUtil";

function getEventAgreement(agreementId) {
  return async dispatch => {
    dispatch({ type: ATTENDANCE_GET_AGREEMENTS, payload: { agreementId } });

    try {
      const agreement = await getAgreement(agreementId);

      dispatch(setAgreements([agreement]));

      return agreement;
    } catch (error) {
      dispatch(setAgreements([]));
    }
  };
}

function getAgreements(filters) {
  return async dispatch => {
    dispatch({ type: ATTENDANCE_GET_AGREEMENTS, payload: { filters } });

    try {
      const { total } = await getAgreementsFromApi(filters, "items=0-0");
      const { data: agreements } = await getAgreementsFromApi(filters, `items=0-${total}`);

      dispatch(setAgreements(agreements));

      return agreements;
    } catch (error) {
      dispatch(setAgreements([]));
    }
  };
}

export function getDefaultAgreement() {
  return async (dispatch, getState) => {
    try {
      const {
        user: { user }
      } = getState();
      const filters = [
        createTermFilter("companyId", user.companyId),
        createTermFilter("defaultAgreement", true)
      ];
      dispatch({ type: ATTENDANCE_GET_DEFAULT_AGREEMENT, payload: { filters } });
      let agreements = await dispatch(getAgreements(filters));
      agreements = agreements.filter(agreement => agreement.companyId === user.companyId);
      const agreement = agreements[0];
      const agreeFilters = [createTermFilter("agreementId", agreement.id)];
      dispatch(getAgrees(agreeFilters));
      return agreement;
    } catch (error) {
      return [];
    }
  };
}

export function getAgreesByAgreementId(agreementId, eventId) {
  return async dispatch => {
    const filters = [createTermFilter("agreementId", agreementId)];

    if (eventId) filters.push(createTermFilter("eventId", eventId));

    return dispatch(getAgrees(filters));
  };
}

function getAgrees(filters) {
  return async (dispatch, getState) => {
    try {
      const {
        user: { user }
      } = getState();

      const agreeFilters = [
        createTermFilter("companyId", user.companyId),
        createTermFilter("userId", user.id),
        ...filters
      ];

      dispatch({ type: ATTENDANCE_GET_AGREES, payload: { agreeFilters } });

      const { total } = await getAgreesFromApi(agreeFilters, "items=0-0");
      const { data: agrees } = await getAgreesFromApi(agreeFilters, `items=0-${total}`);

      dispatch(setAgrees(agrees));

      return agrees;
    } catch (error) {
      dispatch(setAgrees([]));
    }
  };
}

export function setAgrees(agrees) {
  return {
    type: ATTENDANCE_SET_AGREES,
    payload: {
      agrees
    }
  };
}

export function agree(agree) {
  return async dispatch => {
    try {
      dispatch({ type: ATTENDANCE_AGREE });

      const id = await createAgree(agree);

      agree = { ...agree, id };

      dispatch(setAgrees([agree]));

      return agree;
    } catch (error) {
      dispatch(setAgrees([]));
    }
  };
}

export function setAgreements(agreements) {
  return {
    type: ATTENDANCE_SET_AGREEMENTS,
    payload: {
      agreements
    }
  };
}

function openRequiredAgreementModal(eventId, passThroughProps = {}) {
  return async dispatch => {
    const { agreementId, companyId } = await getAgreementId(eventId, event => ({
      agreementId: event.agreementId,
      companyId: event.companyId
    }));

    if (agreementId) {
      await dispatch(getEventAgreement(agreementId));
    }

    return dispatch(
      openDialogWithProps("AGREEMENT", { eventId, companyId, agreementId, passThroughProps })
    );
  };
}

function openRequiredMembershipFieldsModal(eventId, passThroughProps = {}) {
  return async (dispatch, getState) => {
    const {
      events,
      user: { user },
      memberships: { memberships }
    } = getState();

    const { companyId } = eventsSelector.selectById(events, eventId);

    const membershipSchema = await getMembershipSchema(companyId);

    const membership = getMembershipForUser(memberships, user.id, companyId);

    return dispatch(
      openDialogWithProps(DIALOG_CONTENT_TYPE.MEMBERSHIP_FIELDS, {
        userId: user.id,
        companyId,
        eventId,
        schemaFields: membershipSchema?.schemaFields,
        membershipFields: membership?.membershipFields,
        saveVariant: "MULTI_PROCESS_USER_EDIT",
        passThroughProps
      })
    );
  };
}

function openRequiredDepositModal(eventId, passThroughProps = {}) {
  return async (dispatch, getState) => {
    const { companyId } = eventsSelector.selectById(getState().events, eventId);
    return dispatch(openDialogWithProps("DEPOSIT", { eventId, companyId, passThroughProps }));
  };
}

function openBadgesErrorModal(eventId, errorMessage, passThroughProps = {}) {
  return async dispatch => {
    const { saleIds = [] } = passThroughProps;
    return dispatch(openDialogWithProps("BADGE_ERROR", { eventId, saleIds, error: errorMessage }));
  };
}

export function _getAuthorizedSaleUsers(eventId, passThroughProps = {}) {
  return async dispatch => {
    const { saleIds, useNewAttendFlow = false } = passThroughProps;

    try {
      dispatch(saleUsersFetch());
      dispatch(showNotification("Requesting authorization to bid", Severity.info));

      const { data: saleUsers } = await fetchAuthorizedSaleUsers(eventId);

      // if there's not a single good badge, throw error
      if (!saleUsers.some(saleUser => !saleUser.error)) {
        throw new Error(
          saleUsers.find(saleUser => Boolean(saleUser.error))?.error ?? "Unable to register"
        );
      }

      dispatch(saleUsersFetchComplete({ saleUsers }));

      if (!useNewAttendFlow) {
        dispatch(openDialogWithProps(DIALOG_CONTENT_TYPE.ATTEND, { saleId: saleIds[0] }));
      }

      if (saleUsers.every(saleUser => !saleUser.error)) {
        showNotification("Authorization to bid successful");
      }

      return saleUsers;
    } catch (error) {
      dispatch(dismiss());
      dispatch(saleUsersFetchError());

      switch (error?.response?.data) {
        case "User has not agreed to the agreement":
          dispatch(openRequiredAgreementModal(eventId, passThroughProps));
          break;
        case "User must fill out required fields":
          dispatch(openRequiredMembershipFieldsModal(eventId, passThroughProps));
          break;
        case "Deposit required":
          dispatch(openRequiredDepositModal(eventId, passThroughProps));
          break;
        default:
          dispatch(
            openBadgesErrorModal(eventId, error?.response?.data || error.message, passThroughProps)
          );
      }
    }
  };
}

export function getAuthorizedSaleUsers(saleId, eventId) {
  return async dispatch => {
    dispatch(
      _getAuthorizedSaleUsers(eventId, { eventId, saleIds: [saleId], useNewAttendFlow: false })
    );
  };
}

async function getAgreementId(eventId, selector) {
  let event = await getEvent(eventId);

  return selector(event);
}

export function getDealerships(forceSync = false) {
  return async (dispatch, getState) => {
    const {
      attendance: { selectedDealershipId },
      user: { user }
    } = getState();

    const { data: dealerships } = await getUserDealerships(forceSync);
    const companyUser = await getCompanyUserAPI(user?.id);

    const useSameSelectedDealershipId =
      Boolean(selectedDealershipId) &&
      dealerships?.some(dealership => dealership.id === selectedDealershipId);

    const dealershipId = useSameSelectedDealershipId ? selectedDealershipId : dealerships?.[0]?.id;

    batch(() => {
      dispatch(setDealershipId(dealershipId));
      dispatch({ type: ATTENDANCE_SET_DEALERSHIPS, payload: { dealerships } });
      dispatch(setUserLastDealershipSyncMessage(companyUser?.lastDealershipSyncMessage));
    });
  };
}

export function setDealershipId(selectedDealershipId) {
  return {
    type: ATTENDANCE_SET_DEALERSHIP_ID,
    payload: {
      selectedDealershipId
    }
  };
}

export function createMembership(membership) {
  return async dispatch => {
    let id;

    try {
      dispatch({ type: MEMBERSHIP_SAVE });

      id = await createMembershipAPI(membership);

      dispatch({
        type: MEMBERSHIP_CREATE,
        payload: {
          ...membership,
          id,
          status: "PENDING"
        }
      });
    } finally {
      dispatch({ type: MEMBERSHIP_SAVE_COMPLETE });
      return id;
    }
  };
}

function updateMembership(membership, membershipEndpoint) {
  return async dispatch => {
    try {
      dispatch({ type: MEMBERSHIP_SAVE });

      if (membership) {
        await membershipEndpoint(membership);

        dispatch({ type: MEMBERSHIP_UPDATE, payload: { membership } });

        dispatch(showNotification("Membership fields updated", Severity.success));
      }
    } catch (error) {
      dispatch(showError(`Unable to update membership. ${error.response?.data || error.message}`));
    } finally {
      dispatch({ type: MEMBERSHIP_SAVE_COMPLETE });
    }
  };
}

export function userJoinCompany(userId, companyId, membershipFields) {
  return async dispatch => {
    return await dispatch(createMembership({ userId, companyId, membershipFields }));
  };
}

export function userUpdateMembership(userId, companyId, membershipFields) {
  return async (dispatch, getState) => {
    const {
      memberships: { memberships }
    } = getState();

    const membership = getMembershipForUser(memberships, userId, companyId);

    if (membership) {
      await dispatch(updateMembership({ ...membership, membershipFields }, updateMembershipAPI));
    }
  };
}

export function adminUpdateMembership(userId, companyId, membershipFields) {
  return async (dispatch, getState) => {
    const {
      memberships: { memberships }
    } = getState();

    const membership = getMembershipForUser(memberships, userId, companyId);

    if (membership) {
      dispatch(updateMembership({ ...membership, membershipFields }, adminUpdateMembershipAPI));
    }
  };
}

export function registerForCompany(userId, companyId, passThroughProps = {}) {
  return async dispatch => {
    let membershipId;

    try {
      dispatch({ type: MEMBERSHIP_SAVE });

      const filters = [createTermFilter("companyId", companyId, "MUST")];
      const schemaFields = await getMembershipSchemaFields(filters);

      const hasSchemaFields = schemaFields?.filter(field => field.isActive).length > 0;

      if (hasSchemaFields) {
        dispatch(
          openDialogWithProps(DIALOG_CONTENT_TYPE.MEMBERSHIP_FIELDS, {
            userId,
            companyId,
            schemaFields,
            saveVariant: passThroughProps.saveVariant || "USER_JOIN",
            passThroughProps
          })
        );
      } else {
        membershipId = await dispatch(createMembership({ userId, companyId }));
      }
    } finally {
      dispatch({ type: MEMBERSHIP_SAVE_COMPLETE });
      return membershipId;
    }
  };
}

export function getMembershipQueryFilter(query) {
  let formattedQuery, fields;

  if (query.includes("@")) {
    const [email = "", host = ""] = query.split("@");

    formattedQuery = email && host ? `+${email}~ AND +"${host}"` : query;
    fields = ["searchable.email"];
  } else {
    formattedQuery = `${query}`;

    if (isStringNumeric(query)) {
      fields = ["searchable.auctionAccessId^5", "userId^5"];
    } else {
      fields = [
        "searchable.name^10",
        "searchable.firstName^5",
        "searchable.lastName^5",
        "searchable.email^10"
      ];
    }
  }

  return createQueryFilter(formattedQuery, fields);
}

export function getMemberships(companyIds, userId, query, page = 0) {
  return async (dispatch, getState) => {
    dispatch({ type: MEMBERSHIPS_GET });
    let {
      memberships: { sortBy, membershipFilters }
    } = getState();

    let filters = [...membershipFilters];

    if (companyIds) filters.push(createTermsFilter("companyId", companyIds));
    if (userId) filters.push(createTermFilter("userId", userId));
    if (query) {
      filters.push(getMembershipQueryFilter(query));
    }

    const { data: memberships, total } = await getMembershipsFromApi(
      filters,
      getRange(page, 50),
      sortBy
    );

    const filteredMemberships = memberships.filter(membership => membership.user);

    const creditIds = filteredMemberships.map(membership => membership.creditId);
    dispatch(setMembershipTotal(total));
    dispatch(setMembership(filteredMemberships));
    dispatch(getCredits(creditIds));
  };
}

export function getUsersWithoutMemberships(email, page = 0) {
  return async dispatch => {
    dispatch({ type: MEMBERSHIPS_GET });

    const { data: users, total } = await getCompanyUsers(
      [createTermFilter("email", email)],
      getRange(page, 50),
      "-createdOn"
    );

    const memberships = users.map(user => ({ id: user.id, user }));

    dispatch(setMembershipTotal(total));
    dispatch(setMembership(memberships));
  };
}

export function getUserMemberships() {
  return async (dispatch, getState) => {
    dispatch({ type: MEMBERSHIPS_GET });

    const {
      user: { user },
      memberships: { sortBy },
      companies: { companies }
    } = getState();

    const { data: memberships, total } = await getMembershipsFromApi(
      [createTermFilter("userId", user.id)],
      getRange(0, companies.length),
      sortBy
    );

    batch(() => {
      dispatch(setMembershipTotal(total));
      dispatch(setMembership(memberships));
    });
  };
}

export function setMembership(memberships) {
  return {
    type: MEMBERSHIPS_SET,
    payload: {
      memberships
    }
  };
}

export function setMembershipTotal(total) {
  return {
    type: MEMBERSHIPS_SET_TOTAL,
    payload: {
      total
    }
  };
}

export function setMembershipPage(page) {
  return {
    type: MEMBERSHIPS_SET_PAGE,
    payload: {
      page
    }
  };
}

export function toggleMembershipFilter(filterField, filterValue) {
  return {
    type: MEMBERSHIP_TOGGLE_FILTER,
    payload: {
      filterField,
      filterValue
    }
  };
}

export function setMembershipSortBy(sortBy) {
  return {
    type: MEMBERSHIP_SET_SORT_BY,
    payload: {
      sortBy
    }
  };
}

export function toggleMembershipHiddenColumn(companyId, column, isDefault) {
  return {
    type: MEMBERSHIP_TOGGLE_HIDDEN_COLUMN,
    payload: {
      isDefault,
      companyId,
      column
    }
  };
}

async function getCurrentMembership(membershipId) {
  for (let i = 0; i < 3; i++) {
    const _membership = await getMembership(membershipId);

    if (_membership) {
      return _membership;
    } else {
      await new Promise(resolve => setTimeout(resolve, 500));
    }
  }
}

export function attendFlow(passThroughProps = {}) {
  return async (dispatch, getState) => {
    const { companyId, eventId, saleIds = [] } = passThroughProps;

    const {
      config: { showAttendViewOnly },
      user: { user, isAuthorized },
      memberships: { memberships },
      saleUsers,
      sales: { entities: saleEntities }
    } = getState();

    let sales = saleIds.map(saleId => saleEntities[saleId]).filter(sale => sale);

    const hasAttendableSale = sales.some(sale => canAttend(sale));

    if (!isAuthorized) {
      if (showAttendViewOnly && hasAttendableSale) {
        dispatch(openDialogWithProps(DIALOG_CONTENT_TYPE.ATTEND_VIEW_ONLY, { saleIds }));
      }
      return;
    }

    let membership = memberships.find(
      membership => membership.userId === user.id && membership.companyId === companyId
    );
    let fetchedCurrentMembership = false;

    // if no membership - create and wait for approval
    if (!membership) {
      const membershipId = await dispatch(
        registerForCompany(user.id, companyId, {
          ...passThroughProps,
          useNewAttendFlow: true,
          saveVariant: "MULTI_PROCESS_ATTEND"
        })
      );

      if (typeof membershipId !== "number") {
        // went through breaking modal subprocess
        return;
      }

      membership = await getCurrentMembership(membershipId);
      fetchedCurrentMembership = true;
    }

    // if membership status is bad - end process and show status to user
    if (membership.status !== "APPROVED") {
      if (!fetchedCurrentMembership) {
        membership = await getCurrentMembership(membership.id);
      }

      if (membership.status !== "APPROVED") {
        if (showAttendViewOnly && hasAttendableSale) {
          dispatch(
            openDialogWithProps(DIALOG_CONTENT_TYPE.ATTEND_VIEW_ONLY, {
              saleIds,
              variant: "membership"
            })
          );
        } else {
          dispatch(
            openDialogWithProps(DIALOG_CONTENT_TYPE.MEMBERSHIP_STATUS, {
              membershipId: membership.id
            })
          );
        }
        return;
      }
    }

    let badges;

    for (const saleId of saleIds) {
      badges = saleUsers[saleId]?.filter(saleUser => !saleUser.error);
      if (badges && badges.length > 0) break;
    }

    // if no badges - request badges and manage required actions (agree, deposit, membership fields, etc.)
    if (!badges || badges.length === 0) {
      const badges = await dispatch(
        _getAuthorizedSaleUsers(eventId, { ...passThroughProps, useNewAttendFlow: true })
      );

      if (!badges) {
        // went through breaking modal subprocess
        return;
      }
    }

    // open dialog for sale / badges selection
    dispatch(openDialogWithProps(DIALOG_CONTENT_TYPE.ATTEND_NEW, passThroughProps));
    return;
  };
}

async function enableSaleUsers(sale, selectedSaleUserNumbers) {
  try {
    await enableSaleUsersApi(sale.id, selectedSaleUserNumbers);
  } catch (error) {
    return `${sale.name}: ${error.response?.data ?? error.message}`;
  }
}

async function enableRemainingSales(sales, selectedSaleUserNumbers) {
  const chunkSize = 6;
  const responses = [];

  for (let i = 0; i < sales.length; i += chunkSize) {
    const salesChunk = sales.slice(i, i + chunkSize);
    const promises = [];

    for (let j = 0; j < salesChunk.length; j++) {
      promises.push(enableSaleUsers(salesChunk[j], selectedSaleUserNumbers));
    }

    responses.push(...(await Promise.all(promises)));
  }

  return responses;
}

export function attend(saleId, sales, saleUsers, viewOnly = false) {
  return async (dispatch, getState) => {
    let olrHost;

    const {
      user: { user }
    } = getState();

    const popupArgs = [
      "/loading",
      "_blank",
      "location=no, scrollbars=yes, resizable=yes, width=1250, height=950"
    ];

    const { availHeight, availWidth } = window.screen;
    const popup = window.open(...popupArgs);
    popup?.moveTo(0, 0);
    popup?.resizeTo(availWidth, availHeight);

    const selectedSale = sales.find(sale => sale.id === saleId);
    const remainingSales = sales.filter(sale => sale.id !== saleId);
    const saleUserNumbers = saleUsers?.map(saleUser => saleUser.saleUserNumber);

    try {
      if (!viewOnly) {
        await enablePrioritySaleUsers(saleId, saleUserNumbers);
      }

      // builds a link for attendance to OLR

      if (viewOnly) {
        const isGuest = user?.guest;
        const userName = isGuest ? StorageUtil.getItem("guestUserName") : `guest_${user.id}`;

        olrHost = await getGuestAttendUrl(saleId, userName);

        if (isGuest && userName == null) {
          const guestUserName = olrHost
            .split(/[&?]/)
            .find(urlPart => urlPart.startsWith("username="))
            ?.replace("username=", "");

          if (guestUserName != null) {
            StorageUtil.setItem("guestUserName", guestUserName);
          }
        }
      } else {
        olrHost = await getAttendUrl(saleId);
      }

      if (popup?.location) {
        popup.location.href = olrHost;
      }

      if (!viewOnly) {
        // sync information for the rest of the sales in the event
        const responses = await enableRemainingSales(remainingSales, saleUserNumbers);

        // have alert show user that a background task failed
        if (responses.some(response => Boolean(response))) {
          dispatch(
            showError(
              `Attendance failed for: ${responses.filter(response => Boolean(response)).join(", ")}`
            )
          );
        }
      }

      trackEvent({
        category: "Attendance",
        action: viewOnly ? "view only" : "full attend",
        label: `Event:${selectedSale.eventId}-Sale:${selectedSale.id}`
      });

      if (viewOnly && selectedSale.interactionId) {
        // update interaction count
        updateInteractionField(selectedSale.interactionId, "attendanceViewOnlyTotal");
      }

      // why?
      if (GoNative.IsGoNative()) window.history.back();

      popup?.focus();
    } catch (error) {
      dispatch(
        showError(
          `"Attendance failed for (${saleId}) ${selectedSale.name} : ${error.response?.data ??
            error.message}"`
        )
      );
      popup.close();
    }
  };
}
