import { createSelector } from "@reduxjs/toolkit";
import { formatNumber } from "utils/NumberUtil";
import { makeLightsString, getTitle } from "utils/VehicleHelpers";
import { handleLabelConversions } from "utils/LanguageUtil";
import { convertAgeToString, formatDate, formatYear } from "utils/DateUtil";
import startCase from "lodash/startCase";
import { toCamelCase } from "./StringUtil";

const _getCompanyAssetSchemas = (companySchemaIds = [], schemas = []) => {
  return companySchemaIds.reduce((companySchemas, schemaId) => {
    const schema = schemas.find(schema => schema.id === schemaId);

    if (schema) {
      companySchemas.push(schema);
    }

    return companySchemas;
  }, []);
};

export const getCompanyAssetSchemas = createSelector(
  state => state.companies.companies,
  state => state.assets.schemas,
  (_, companyId) => companyId,
  (_, companyId, assetSchemaIds) => assetSchemaIds,
  (companies = [], assetSchemas = [], companyId, assetSchemaIds) => {
    const company = companies.find(company => company.id === companyId);
    const companyAssetSchemas = _getCompanyAssetSchemas(company?.assetSchemaIds, assetSchemas);

    if (Array.isArray(assetSchemaIds)) {
      return filterAssetSchemas(companyAssetSchemas, assetSchemaIds);
    }

    return companyAssetSchemas;
  }
);

export const filterAssetSchemas = (schemas = [], assetSchemaIds = []) => {
  return schemas.filter(schema => assetSchemaIds.includes(schema.id));
};

export function getAssetSchemaLabel(schemaName, languageMap) {
  const camelCased = toCamelCase(schemaName);

  if (languageMap == null) return schemaName;

  if (camelCased in languageMap) {
    return languageMap[camelCased];
  }

  return schemaName;
}

export function getDescription(asset) {
  const arr = [];

  if (asset) {
    const headerValue = asset.uifields?.header?.value;
    const bodyLabel = asset.uifields?.body?.label;
    const bodyValue = asset.uifields?.body?.value;

    arr.push(headerValue || getDefaultTitle(asset.fields?.schemaName));

    if (bodyLabel && bodyValue) {
      arr.push(`${bodyLabel}: ${bodyValue}`);
    }
  }

  return arr.join(", ");
}

export function getInventoryTitle(asset) {
  const schemaName = asset?.fields?.schemaName;

  return asset?.uifields?.header?.value || getDefaultTitle(schemaName);
}

export function getInventoryEnrichedTitle(asset) {
  return `${getInventoryTitle(asset)} ${asset?.uifields?.body?.value ?? ""}`;
}

export function getDefaultTitle(schemaName) {
  switch (schemaName) {
    case "Bloodstock":
      return "Unnamed";
    case "Cattle":
      return "No Description";
    default:
      return "N/A";
  }
}

export function getWeightWithUnitType(asset, property) {
  const value = asset?.fields?.[property];
  const unitType = asset?.fields?.weightUnit;

  if (value && unitType) {
    return `${value} ${unitType}`;
  }

  return value;
}

export function isDateField(field) {
  return field?.toLowerCase() === "dob";
}

export function isAgeField(field) {
  return field?.toLowerCase() === "age";
}

function getIsYear(isYear) {
  return isYear === "true" || isYear === true;
}

export function handleAnyConversions(field, value, options = {}) {
  if (value && isDateField(field)) {
    try {
      if (typeof value === "string") {
        const _value = Number(value);

        if (isNaN(_value)) {
          throw new Error();
        }
        value = _value;
      }

      // force to stay in same timezone server is on
      const serverTimezone = "America/New_York";

      if (getIsYear(options.isYear)) {
        return formatYear(value, serverTimezone);
      }

      return formatDate(value, options?.locale, serverTimezone);
    } catch (error) {
      return value;
    }
  } else if (isAgeField(field)) {
    return convertAgeToString(value);
  }

  if (field.toUpperCase() === "ODOMETER") {
    return formatMileage(field, value);
  }

  return value;
}

export function getFields(lot, asset, disclosure, languageMap) {
  switch (asset?.fields?.schemaName) {
    case "Bloodstock":
      return getBloodstockFields(asset?.fields, languageMap);
    case "Automotive":
      return getAutomotiveFields(lot, asset, disclosure, languageMap);
    case "Merchandise":
      return getMerchandiseFields(asset, disclosure, languageMap);
    default:
      return formatFields(getAssetFields(asset), languageMap);
  }
}

function getAutomotiveFields(lot, asset, disclosure, languageMap = {}) {
  const { fields, assetAnnouncements, estimates } = asset;

  if (!fields) return [];

  const mmr = estimates.find(est => est.source === "Mmr");
  const keys = lot?.searchable?.keys;
  const hasKeys = Boolean(keys) || keys === 0;
  const mileage =
    fields.mileage != null
      ? `${fields.mileage ? fields.mileage.toLocaleString("en-US") : "0"} ${
          fields.odometerUnit ? fields.odometerUnit : "mi"
        }`
      : null;

  const items = [
    { id: "vin", value: fields.vin },
    { id: "grade", value: fields.overallConditionGrade ? fields.overallConditionGrade : null },
    { id: "mmr", value: mmr?.average ? formatNumber(mmr.average) : null },
    { id: "lights", value: makeLightsString(fields) },
    { id: "keys", value: hasKeys ? keys : null },
    { id: "body style", value: fields.bodyStyle },
    { id: "trim", value: fields.trim },
    { id: "interior color", value: fields.interiorColor },
    { id: "exterior color", value: fields.exteriorColor },
    { id: "interior material", value: fields.interiorMaterial },
    { id: "mileage", value: mileage },
    { id: "odometer status", value: fields.odometerStatus },
    { id: "engine", value: fields.engineDetailed },
    { id: "fuel type", value: fields.fuelType },
    { id: "drive", value: fields.drive },
    { id: "transmission", value: fields.transmission },
    { id: "title", value: getTitle(fields) },
    { id: "announcements", value: assetAnnouncements?.length > 0 ? assetAnnouncements : [] },
    { id: "disclosure", value: disclosure ? disclosure.disclosure : null },
    { id: "externalLinkUrl", value: fields.externalLinkUrl }
  ];

  return items.map(item => {
    item.label = handleLabelConversions(item.id, languageMap);
    return item;
  });
}

function getBloodstockFields(fields, languageMap) {
  if (!fields) return [];

  const items = [
    { id: "name", value: fields.name || getDefaultTitle(fields.schemaName) },
    { id: "type", value: fields.type },
    { id: "sex", value: fields.sex },
    { id: "covering sire", value: fields.coveringSire },
    { id: "grand sire", value: fields.grandSire },
    { id: "sire", value: fields.sire },
    { id: "dam", value: fields.dam },
    { id: "pregnant", value: fields.pregnantBool },
    { id: "state", value: fields.state },
    { id: "country", value: fields.country },
    { id: "dob", value: fields.dateOfBirth },
    { id: "barn", value: fields.barn },
    { id: "utTime", value: fields.utTime },
    { id: "blackType", value: fields.blackTypeBool },
    { id: "externalLinkUrl", value: fields.externalLinkUrl },
    { id: "age", value: handleAnyConversions("age", fields.age) },
    { id: "owner", value: fields.owner },
    { id: "color", value: fields.color },
    { id: "comments", value: fields.comments },
    { id: "location", value: fields.location },
    { id: "originLocation", value: fields.originLocation },
    { id: "info", value: fields.info }
  ];

  return items.map(item => {
    item.label = handleLabelConversions(item.id, languageMap);
    return item;
  });
}

function getMerchandiseFields(asset, disclosure, languageMap) {
  const { fields, assetAnnouncements } = asset;

  if (!fields) return [];

  const items = [
    { id: "count", value: fields.count },
    { id: "merchandise", value: fields.merchandise },
    { id: "short description", value: fields.description1 },
    { id: "long description", value: fields.description2 },
    { id: "announcements", value: assetAnnouncements?.length > 0 ? assetAnnouncements : false },
    { id: "disclosure", value: disclosure ? disclosure.disclosure : false },
    { id: "externalLinkUrl", value: fields.externalLinkUrl }
  ];

  return items.map(item => {
    item.label = handleLabelConversions(item.id, languageMap);
    return item;
  });
}

function getAssetFields(asset) {
  const fields = asset?.fields;
  if (fields) {
    if (fields.schemaName === "Cattle") {
      return {
        owner: fields.owner,
        secondDescription: fields.secondDescription,
        thirdDescription: fields.thirdDescription,
        sire: fields.sire,
        dam: fields.dam,
        dob: fields.dateOfBirth,
        age: handleAnyConversions("age", fields.age),
        totalWeight: getWeightWithUnitType(asset, "totalWeight"),
        averageWeight: getWeightWithUnitType(asset, "averageWeight"),
        baseWeight: getWeightWithUnitType(asset, "baseWeight"),
        weightRange: getWeightWithUnitType(asset, "weightRangeString"),
        weaningWeight: getWeightWithUnitType(asset, "weaningWeight"),
        yearlingWeight: getWeightWithUnitType(asset, "yearlingWeight"),
        birthWeight: getWeightWithUnitType(asset, "birthWeight"),
        sc: fields.scrotalCircumference,
        weighConditions: fields.weighConditions,
        breed: fields.breed,
        color: fields.color,
        comments: fields.comments,
        delivery: fields.delivery,
        feed: fields.feed,
        flesh: fields.flesh,
        location: fields.location,
        frame: fields.frame,
        hauled: fields.hauled,
        headCount: fields.headCount,
        health: fields.health,
        originLocation: fields.originLocation,
        sex: fields.sex,
        shrink: fields.shrinkString || fields.shrink,
        slide: fields.slideString || fields.slide,
        weaned: fields.weaned,
        pen: fields.pen,
        externalLinkUrl: fields.externalLinkUrl
      };
    } else {
      const { schemaName, ...rest } = fields;
      return rest;
    }
  }
}

function formatFields(fields, languageMap) {
  if (!fields) return [];

  return Object.keys(fields).reduce((formattedFields, fieldKey) => {
    const fieldValue = fields[fieldKey];
    if (fieldValue != null) {
      const id = startCase(fieldKey)?.toLowerCase();
      formattedFields.push({
        id: id,
        label: handleLabelConversions(id, languageMap),
        value: fieldValue
      });
    }
    return formattedFields;
  }, []);
}

function formatMileage(label, value) {
  if (!value) {
    return "N/A";
  }

  let values = value.split(" ");
  let mileage = formatNumber(values[0]);

  if (mileage === "NaN") {
    mileage = values[0];
  }

  if (values.length > 0 && values[1] != null) {
    return `${mileage} ${values[1]}`;
  }

  return `${mileage}`;
}
